/**
 * InputGroupClear
 * @param {HTMLElement} el element
 */
export function inputGroupClear(el: HTMLElement) {
    const inputEl = el.querySelector('.form-control') as HTMLInputElement;
    const inputGroupAppendIconEl = el.querySelector('.input-group-append .input-group-text .icon') as HTMLElement;

    /**
     * onChange
     */
    function onChange() {
        if (inputEl.value.length > 0) $(inputGroupAppendIconEl).show();
        else $(inputGroupAppendIconEl).hide();
    }

    /**
     * onClick
     */
    function onClick() {
        inputEl.value = '';
        $(inputGroupAppendIconEl).hide();
        $(el).removeClass('has-value');
        inputEl.dispatchEvent(new Event('input-group:cleared'));
        $(inputEl).trigger('focus');
    }

    inputGroupAppendIconEl.addEventListener('click', onClick);
    inputEl.addEventListener('input', onChange);
    $(inputEl).on('input-group:clear', onClick);

    if (!inputEl.value) $(inputGroupAppendIconEl).hide();
}
