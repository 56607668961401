export const NOTIFICATION_GLOBAL_MESSAGE = {
    ERROR: 'An error occured while processing your data. Please, try again',
    SERVER_ERROR: 'An error occured while processing your data. Please, reload the page'
};

export const NOTIFICATION_TIME = {
    SHORT: 3500,
    LONG: 6500
};

export const NOTIFICATION_TYPE = {
    INFO: 'info',
    WARNING: 'warning',
    DANGER: 'danger',
    SUCCESS: 'success'
};

/**
 * Check if Notification options has any problem
 * @param {Object} options - Notification options
 */
function checkOptions(options: any) {
    const types = Object.keys(NOTIFICATION_TYPE);

    if (options.type && !types.includes(options.type.toUpperCase())) {
        throw new Error(`Notification option type "${options.type}" is invalid. Should be ${types.join(', ')}`);
    }
}

/**
 * get icon
 * @param {string} type success/danger/warning/info
 * @return {string} icon id
 */
function icon(type: string): string {
    if (type === 'success') {
        // check_circle
        return 'm424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z';
    }
    // info
    return 'M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z';
}

/**
 * Build alert template
 * @param {Object} options - Notification options
 * @return {string} alert template
 */
function buildTemplate(options: any): string {
    return `
        <div class="notification${options.floating ? ' notification--floating' : ''}">
            <div class="alert alert-${options.type} fade" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentcolor"><path d="${icon(options.type)}"></path></svg>

                <div class="alert-body">
                    <p class="mb-0">${options.message}</p>
                </div>

${!options.time && options.dismissible
        ? `<button type="button" class="btn-icon btn-close" data-dismiss="alert" aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#999"><path d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"/></svg>
            </button>`
        : ''
}
            </div>
        </div>
    `;
}

/**
 * Notification
 *
 * This class uses few events and funtion from BS4 alerts.
 * See more at https://getbootstrap.com/docs/4.0/components/alerts/
 *
 * @param {any} options - Notification options
 * @returns {Object} public functions
 */
function notification(options: any): { show(): void, hide(): void } {
    let $notification = $(buildTemplate(options));
    let time: number;

    checkOptions(options);

    /**
     * on click
     * @param {JQuery.MouseEventBase} event - mouse event
     */
    function onClick(event: JQuery.MouseEventBase) {
        const $alert = $notification.find('.alert');
        const isNotAlert = !$alert[0].contains(event.target);
        if (isNotAlert) $alert.trigger('closed.bs.alert');
    }

    /**
     * Hide notification
     */
    function hide() {
        const $alert = $notification.find('.alert');
        $alert.removeClass('show');
        $notification.remove();

        $('body').off('click', onClick);

        if (time) {
            clearTimeout(time);
            time = NaN;
        }
    }

    /**
     * Show notification
     */
    function show() {
        const $alert = $notification.find('.alert');
        if (!options.floating && !options.stack) $(options.context).empty();
        $(options.context).append($notification);
        $alert.addClass('show');
        $alert.on('closed.bs.alert', hide);
    }

    if (options.time) time = window.setTimeout(hide, options.time);
    if (options.floating) $('body').on('click', onClick);

    return {
        show,
        hide
    };
}

/**
 * Notification element level
 *
 * @param {Object} options - Notification options
 * @returns {Object} instance
 */
function elementLevel(this: JQuery, options: any) {
    const $element = $(this);
    return notification($.extend({}, options, {
        context: $element
    }));
}

/**
 * Notification page level
 *
 * @param {Object} options - Notification options
 * @returns {Object} instance
 */
function pageLevel(options: any) {
    return notification($.extend({}, options, {
        context: $('body'),
        floating: true
    }));
}

$.fn.notification = elementLevel;
$.notification = pageLevel;
$.NOTIFICATION_TIME = NOTIFICATION_TIME;
$.NOTIFICATION_TYPE = NOTIFICATION_TYPE;
$.NOTIFICATION_GLOBAL_MESSAGE = NOTIFICATION_GLOBAL_MESSAGE;
