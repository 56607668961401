/**
 * Form Control
 * @param {HTMLInputElement} el element
 */
export function formControl(el: any) {
    const inputGroupEl = el.parentElement as HTMLElement;
    const isPartOfInoutGroup = inputGroupEl.classList.contains('input-group');

    /**
     * onChange
     */
    function onChange() {
        if (isPartOfInoutGroup) {
            inputGroupEl.classList.toggle('has-value', (el.value !== '' && el.value !== undefined));
        } else {
            const isEmpty = [
                el.value === undefined,
                el.value === '',
                el.classList.contains('has-placeholder') && el.selectedIndex === 0
            ].some(Boolean);
            el.classList.toggle('has-value', !isEmpty);
        }
    }

    el.addEventListener('change', onChange);

    if (!el.hasAttribute('disabled') && !el.classList.contains('disabled')) onChange();
}
