import { debounce } from "framework/core/utils/functions";
import { handleServerErrors } from "../core/components/request";
import { breakpointUp, breakpointSize } from '../core/utils/css';
import * as pageScroll from '../core/pageScroll';

const searchOverlay = document.querySelector<HTMLElement>('[data-component="search-overlay"]')
const searchOverlayCloseBtn = searchOverlay?.querySelector<HTMLButtonElement>('.close-btn');
const form = searchOverlay?.querySelector<HTMLFormElement>('form[name="simpleSearch"]');
const searchInput = searchOverlay?.querySelector<HTMLInputElement>('input[name="q"]');
const suggestionsWrapper = searchOverlay?.querySelector<HTMLElement>('.suggestions-wrapper');
const $suggestionsNoResult = suggestionsWrapper ? $('.suggestions-no-result', suggestionsWrapper) : $();
const $suggestionsResult = suggestionsWrapper ? $('.suggestions-result', suggestionsWrapper): $();
const iconSpinner = searchOverlay?.querySelector<HTMLElement>('.icon-spinner');
const iconSearch = searchOverlay?.querySelector<HTMLElement>('.icon-search');

const endpoint = suggestionsWrapper?.dataset.url;
const minChars = Number(searchOverlay?.dataset.minChars) || 3;
const trendingSearchesEnabled = searchOverlay?.dataset.trendingSearches === 'true';

/**
 * Current search term displayed.
 */
let currentTerm: string | null = null;

/**
 * `true` if the trending searches for `.suggestions-no-result` have been
 * retrieved and added to the element.
 */
let hasNoResultContent = false;

/**
 * Current JQuery XMLHttpRequest to fetch suggestions, which will be aborted
 * if it is still in progress when new suggestions are needed.
 */
let currentXHR: JQueryXHR|null = null;

searchInput?.addEventListener('focus', onFocusSearchInput);
// Bind input event listener for case where user has focused before the focus listener has been bound
searchOverlayCloseBtn?.addEventListener('click', onClickSearchOverlayCloseBtn);
form?.addEventListener('submit', onSubmit);
searchInput?.addEventListener('input', debounce(updateSearchOverlay, 350));
searchInput?.addEventListener('input-group:cleared', onInputClear);

/**
 * Expand the search input field and display the suggestions
 * if they have any content.
 */
function showOverlay() {
    searchOverlay?.classList.add('open');
    searchOverlay?.addEventListener('keydown', onKeyDown);
    document.addEventListener('click', onClickOutside);

    pageScroll.stop();
}

function spinner(show: boolean) {
    if (show) {
        iconSpinner?.classList.remove('d-none');
        iconSearch?.classList.add('d-none');
    } else {
        iconSpinner?.classList.add('d-none');
        iconSearch?.classList.remove('d-none');
    }
}

/**
 * Collapse the search input field and hide the suggestions
 * if they are currently visible.
 */
function hideOverlay() {
    searchOverlay?.classList.remove('open');
    searchOverlay?.removeEventListener('keydown', onKeyDown);
    document.removeEventListener('click', onClickOutside);

    pageScroll.start();
}

/**
 * Update the suggestions content with the response from the server.
 *
 * @param response HTML from SearchServices-GetSuggestions
 * @param term search term that was used
 */
function displaySuggestions(response: any, term: string) {
    spinner(false);

    if (!term) {
        $suggestionsResult.hide();
        $suggestionsNoResult.empty().append(response).show();
        hasNoResultContent = true;
    } else {
        $suggestionsNoResult.hide();
        $suggestionsResult.empty().append(response).show();
        $suggestionsResult[0]?.dispatchEvent(new Event('suggestions:update', { bubbles: true, cancelable: false }));
        currentTerm = term;
    }
}

/**
 * Retrieve search suggestions from the server, and display them once the
 * server responds.
 *
 * @param value current search term, or nothing for default suggestions
 */
function getSuggestions(value: string = '') {
    spinner(true);

    if (currentXHR) currentXHR.abort();

    currentXHR = $.ajax({
        url: endpoint,
        data: {
            q: value,
            products: breakpointUp(breakpointSize.LG) // only fetch products on large screens
        },
        method: 'GET',
        success: response => displaySuggestions(response, value),
        error: (err) => {
            spinner(false);
            handleServerErrors(err);
        }
    });
}

/**
 * Collapse the search field and hide the search suggestions on any click
 * outside the search wrapper container.
 */
function onClickOutside(event: MouseEvent) {
    const target = event.target as HTMLElement|null;
    if (target && !(suggestionsWrapper?.contains(target) || form?.contains(target))) {
        hideOverlay();
    }
}

/**
 * Reset the suggestions back to the defaults when the search field
 * is cleared by clicking the cross.
 */
function onInputClear() {
    $suggestionsNoResult.show();
    $suggestionsResult.empty().hide();
}

/**
 * When the customer types in the search box, retrieve suggestions from the
 * server and display them.
 */
function updateSearchOverlay() {
    if (!searchOverlay?.classList.contains('open')) showOverlay();

    const value = searchInput ? searchInput.value.trim() : '';

    if (value.length >= minChars) {
        if (value !== currentTerm) {
            getSuggestions(value);
        }
    } else if (hasNoResultContent || !trendingSearchesEnabled) {
        $suggestionsNoResult.show();
        $suggestionsResult.empty().hide();
        currentTerm = null;
    } else {
        getSuggestions();
    }
}

/**
 * Prevent form submission if the number of characters entered
 * is too short.
 */
function onSubmit(event: SubmitEvent) {
    const value = searchInput ? searchInput.value.trim() : '';
    if (value.length < minChars) event.preventDefault();
}

function onFocusSearchInput() {
    // Let event processing finish before drawing the expanded input box.
    requestAnimationFrame(() => updateSearchOverlay());
}

function onClickSearchOverlayCloseBtn() {
    hideOverlay();
}

function onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') hideOverlay();
}
